export enum Param {
	USER_HASH_ID = "userHashId",
	TAB_MENU = "tabMenu",
	INTERVENTION_MENU = "interventionMenu",
	INTERVENTION_TRACKER = "interventionTracker",
	FILE_ID = "fileId",
}

export enum ContactTabPath {
	DATA = "data",
	INSIGHTS = "insights",
	NETWORK = "network",
	PROFILE = "profile",
	ROM = "rom",
	FILES = "files",
	NOTES = "notes",
	INTERVENTION = "intervention",
}

export enum InterventionMenuPath {
	NOTES = "notes",
	TRACKERS = "trackers",
	PSYCHOEDUCATION = "psychoeducation",
	BEHAVIOR_EXPERIMENT = "behavior_experiment",
	DIARIES = "diaries",
	SESSION = "session",
	ASSESSMENT = "assessment",
}

export enum QueryString {
	Event = "event",
}

export enum CaseloadSubPath {
	CONNECTED = "connected",
	PENDING = "pending",
}

export const Path = {
	APP: "/app",
	APP_THERAPIST_INFO: "/app/therapist/info",
	APP_THERAPIST_EDIT_BIO: "/app/therapist/edit/bio",
	APP_THERAPIST_EDIT_PERSONAL: "/app/therapist/edit/personal",
	APP_THERAPIST_EDIT_AVATAR: "/app/therapist/edit/avatar",
	APP_CLIENT_DETAIL: `/app/client/:${Param.USER_HASH_ID}`,
	APP_CLIENT_DETAIL_MENU: `/app/client/:${Param.USER_HASH_ID}/:${Param.TAB_MENU}`,
	APP_CLIENT_DETAIL_MENU_INTERVENTION: `/app/client/:${Param.USER_HASH_ID}/${ContactTabPath.INTERVENTION}/:${Param.INTERVENTION_MENU}`,
	APP_CLIENT_DETAIL_MENU_INTERVENTION_TRACKER: `/app/client/:${Param.USER_HASH_ID}/${ContactTabPath.INTERVENTION}/${InterventionMenuPath.TRACKERS}/:${Param.INTERVENTION_TRACKER}`,
	APP_CLIENT: "/app/client",
	APP_THERAPIST_DETAIL: `/app/therapist/:${Param.USER_HASH_ID}`,
	APP_THERAPIST_DETAIL_MENU: `/app/therapist/:${Param.USER_HASH_ID}/:${Param.TAB_MENU}`,
	APP_THERAPIST: "/app/therapist",
	APP_CONNECTION_REQUEST: "/app/connection-requests",
	APP_LIBRARY: "/app/library",
	APP_VIDEO_CALL: "/video.html",
	APP_INACTIVE_CLIENT: "/app/inactive-clients",
	APP_TIME_TRACKING: "/app/time-entries",
	APP_ACCOUNT_SETTINGS: "/app/account-settings",
	APP_TWO_FA: "/app/two-factor-authentication",
	APP_THERAPIST_EMAIL_VERIFICATION: "/app/email-verification",
	APP_CALENDARS: "/app/calendars",
	APP_THERAPIST_CASELOAD: "/app/caseload",
	APP_THERAPIST_CASELOAD_CONNECTED: `/app/caseload/${CaseloadSubPath.CONNECTED}`,
	APP_THERAPIST_CASELOAD_PENDING: `/app/caseload/${CaseloadSubPath.PENDING}`,
	APP_TT_EXPORT: `/app/time-tracking/export/:${Param.FILE_ID}`,
};
